import React, { useEffect } from "react";

import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import about_shape01 from "../assets/img/images/h2_about_shape01.png";
import about_shape02 from "../assets/img/images/h2_about_shape02.png";
import semicon_1 from "../assets/img/smicon/01.svg";
import semicon_2 from "../assets/img/smicon/02.svg";
import semicon_3 from "../assets/img/smicon/03.svg";
import semicon_4 from "../assets/img/smicon/04.svg";
import semicon_5 from "../assets/img/smicon/05.svg";
import semicon_6 from "../assets/img/smicon/06.svg";
import semicon_7 from "../assets/img/smicon/07.svg";
import semicon_8 from "../assets/img/smicon/08.svg";
import semicon_9 from "../assets/img/smicon/09.svg";
import semicon_10 from "../assets/img/smicon/10.svg";
import announce from "../assets/img/smicon/01-an.svg";
import announcement from "../assets/img/smicon/02-an.svg";
import a_3 from "../assets/img/smicon/03-an.svg";
import ann from "../assets/img/smicon/04-an.svg";
import { Link } from "react-router-dom";
import AOS from "aos";
const ModuleDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <button class="scroll-top scroll-to-target" data-target="html">
        <i class="fas fa-angle-up"></i>
      </button>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/modules">Module</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Institute
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="about-area-three overview-area">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-9">
                <div class="about-img-wrap-three">
                  <img
                    src="assets/img/images/h2_about_img01.jpg"
                    alt=""
                    data-aos="fade-down-right"
                    data-aos-delay="0"
                    class="aos-init aos-animate"
                  />
                  <img
                    src="assets/img/images/h2_about_img02.jpg"
                    alt=""
                    data-aos="fade-left"
                    data-aos-delay="400"
                    class="aos-init aos-animate"
                  />
                  <div
                    class="experience-wrap aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <h2 class="title">
                      5 <span>Years</span>
                    </h2>
                    <p>Of Experience in This ERP Software Company.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about-content-three">
                  <div class="section-title-two mb-20 tg-heading-subheading animation-style3">
                    <span class="sub-title">Company Overview</span>
                    <h2 class="title tg-element-title">
                      <div class="split-line">
                        <div class="letter">W</div>
                        <div class="letter">h</div>
                        <div class="letter">a</div>
                        <div class="letter">t</div>
                        <div class="letter">i</div>
                        <div class="letter">s</div>
                        <div class="letter">E</div>
                        <div class="letter">d</div>
                        <div class="letter">p</div>
                        <div class="letter">e</div>
                        <div class="letter">d</div>
                        <div class="letter">i</div>
                        <div class="letter">a</div>
                        <div class="letter">?</div>
                      </div>
                    </h2>
                  </div>
                  <p class="info-one">
                    Educational (ERP) management system software for
                    institutions to manage daily activities like students
                    records, examination, fees and expense management. We have
                    different logins and dashboards for Admin, teachers,
                    students, and parents. The software is online. Student
                    enrollment is very easy to manage and use throughout our
                    software.&nbsp;Learn More
                  </p>
                  <h3>Who uses Edpedia?</h3>
                  <p>Schools, Colleges and Universities</p>
                  <div class="overview-content pt-20">
                    <div class="content-bottom">
                      <ul class="list-wrap">
                        <li>
                          <div class="icon">
                            <i class="flaticon-trophy"></i>
                          </div>
                          <div class="content">
                            <h2 class="count">235+</h2>
                            <p>Best Award</p>
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <i class="flaticon-rating"></i>
                          </div>
                          <div class="content">
                            <h2 class="count">98k</h2>
                            <p>Happy Clients</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="about-shape-wrap-two">
            <img src={about_shape01} alt="" />
            <img src={about_shape02} alt="" />
            <img
              src="assets/img/images/h2_about_shape03.png"
              alt=""
              data-aos="fade-left"
              data-aos-delay="500"
              class="aos-init aos-animate"
            />
          </div>
        </section>

        <section class="request-area-two">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <h2 class="title text-center white-color">
                  Features&nbsp;In This Module&nbsp;
                </h2>
              </div>
            </div>
            <ul class="ullibox">
              <li>
                <div class="smbox">
                  <img src={semicon_1} alt="" />
                  <p class="">Brithday</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_2} alt="" />
                  <p class="">Exams</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_3} alt="" />
                  <p class="">Cultural</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_4} alt="" />
                  <p class="">Study Material</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_5} alt="" />
                  <p class="">Student</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_6} alt="" />
                  <p class="">Time-Table</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_7} alt="" />
                  <p class="">Syllabus</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_8} alt="" />
                  <p class="">Admissions</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_9} alt="" />
                  <p class="">Attendance</p>
                </div>
              </li>
              <li>
                <div class="smbox">
                  <img src={semicon_10} alt="" />
                  <p class="">Holidays</p>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section class="pt-100 pb-100">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <h2 class="title text-center">Other Features</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ul class="anon-box">
                  <li>
                    <img src={announce} alt="" />
                    <p>Announcements</p>
                  </li>
                  <li>
                    <img src={announcement} alt="" />
                    <p>Announcements</p>
                  </li>
                  <li>
                    <img src={a_3} alt="" />
                    <p>Announcements</p>
                  </li>
                  <li>
                    <img src={ann} alt="" />
                    <p>Announcements</p>
                  </li>
                </ul>
                <div class="text-center">
                  <a
                    href="services.html"
                    class="btn aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="700"
                  >
                    &nbsp; Explore More Features
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="request-area-two">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="request-content-two">
                  <div class="section-title-two white-title mb-15 tg-heading-subheading animation-style3">
                    <h2 class="title tg-element-title">Become a Partner</h2>
                  </div>
                  <p>
                    Ever find yourself staring at your computer screen a good
                    consulting slogan to come to mind? Oftentimes.
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="request-form-wrap">
                  <form action="#">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-grp">
                          <input type="text" placeholder="Name *" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-grp">
                          <input type="email" placeholder="E-mail *" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-grp">
                          <input type="number" placeholder="Phone *" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <button type="submit">Send Now</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="request-shape-wrap">
            <img src="assets/img/images/h2_request_shape01.png" alt="" />
            <img
              src="assets/img/images/h2_request_shape02.png"
              alt=""
              data-aos="fade-left"
              data-aos-delay="200"
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default ModuleDetails;
